<template>
  <div>
    <div class="flex">
      <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

        <h1 class="text-2xl font-bold mb-5">Update Password</h1>

        <form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="admin">
          
          <input
            type="email"
            :value="email"
            autocomplete="username"
            class="hidden"
          />

          <form-group
            v-model="form.data.old_password.value"
            type="password"
            name="old_password"
            :form="form"
            :can-toggle-password="false"
            autocomplete="current-password"
          >
            Old Password
          </form-group>

          <form-group
            v-model="form.data.password.value"
            type="password"
            name="password"
            :form="form"
            :can-toggle-password="false"
            autocomplete="new-password"
          >
            Password
          </form-group>

          <form-group
            v-model="form.data.password_confirmation.value"
            type="password"
            name="password_confirmation"
            :form="form"
            :can-toggle-password="false"
            autocomplete="new-password"
          >
            Confirm Password
          </form-group>

          <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span class="">{{ getFormError(form) }}</span>
            </div>
          </template>

          <div class="text-right">
            <button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading">
              <sm-loader-white v-if="form.loading" />
              <span v-else>Change Password</span>
            </button>
          </div>

        </form>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$options.basicForm([
          { name: 'old_password', rules: 'required'},
          { name: 'password', rules: 'required|min:8|alphaNum'},
          { name: 'password_confirmation', rules: 'required|same:password' }
        ]),
      }
    },
    computed: {
      email() {
        return this.user?.email;
      },
    },
    methods: {
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = false;
        this.form.loading = true;
        await this.sendRequest('authentication.updatePassword', {
          data: this.getFormData(),
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: 'Password changed successfully',
              button: {
                text: 'Go to Dashboard',
                onClick: () => this.$router.push({ name: 'dashboard' }),
              }
            });
            this.form = this.resetForm(this.form);
          },
          error: error => {
            this.form.error = error;
            this.mapFormErrors(this.form, error?.response?.data?.errors);
          }
        });
        this.form.loading = false;
      }
    }
  }
</script>